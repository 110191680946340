import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = {
  theme: {
    themes: {
      light: {
        primary: '#6300bd',
        secondary: '#fd1ba1',
        accent: '#f80035',
        error: '#b71c1c',
        info: '#e5e5e5',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
};

export default new Vuetify(vuetify);
