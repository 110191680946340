import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Auth } from "aws-amplify";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/users",
    name: "users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import("./views/Users.vue"),
    component: () => import("../views/Users.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("../views/ChangePassword.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Login.vue"),
    meta: { layout: "FullPageLayout" },
  },
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = !!(await Auth.currentUserInfo());

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    if (requiresAuth) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userRoles =
        authenticatedUser.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
      let role = to.meta.role;
      if (typeof to.meta.role !== "undefined") {
        let authorised = false;
        for (var i = 0; i < userRoles.length; i++) {
          role.forEach(function (r) {
            if (userRoles[i] === r) {
              authorised = true;
            }
          });
        }
        if (!authorised) {
          next("/");
        }
      }
    }
    next();
  }
});

export default router;
