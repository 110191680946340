<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: "App",
  async mounted() {
    await this.$store.dispatch("authAction");
  },
  // methods: {
  //   onSignOut() {
  //     Auth.signOut()
  //       // eslint-disable-next-line prettier/prettier
  //       .then(data => {
  //         this.$store.state.user = null;
  //         this.$store.state.signedIn = !!data;
  //         this.$router.push("/");
  //       })
  //       .catch((err) => console.log(err));
  //   },
  // },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
