<template>
  <div class="profile">
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" top>
      {{ snackbarText }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-card class="mx-auto" max-width="700" style="margin-top: -64px">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text"
          >Update Your Profile</v-toolbar-title
        >
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-layout column align-center>
          <v-flex class="mt-5">
            <v-avatar size="110" color="indigo" v-if="!s3Avatar">
              <v-icon size="90" dark>mdi-account-circle</v-icon>
            </v-avatar>
            <v-avatar size="110" v-if="s3Avatar">
              <img :src="s3Avatar" :alt="form.firstName" />
            </v-avatar>
          </v-flex>
        </v-layout>

        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="form.firstName"
                label="First name"
                :rules="inputRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.lastName"
                label="Last name"
                :rules="inputRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-phone"
                label="Phone number"
                v-model="form.phone"
                :rules="telephoneRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn @click="onUserSubmit" color="info">Update profile</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "Profile",

  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    s3Avatar: null,
    inputRules: [(v) => !!v || "This field is required"],
    fileRules: function ({ name, size }) {
      if (name) {
        if (size > 2000000) {
          return "Avatar size should be less than 2 MB!";
        }
      }
      return true;
    },
    telephoneRules: [
      (v) =>
        /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(v) ||
        "International format required eg +44",
    ],
    postcodeRules: [
      (v) =>
        /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i.test(v) ||
        "Please enter a UK postcode",
    ],
    username: "",
    message: {
      showError: false,
      errorMessage: null,
      show: false,
      message: null,
    },
    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 3000,
  }),
  created() {
    this.findUser();
  },
  methods: {
    async findUser() {
      let user = await Auth.currentAuthenticatedUser();
      let userAttributes = await Auth.userAttributes(user);

      for (var i = 0; i < userAttributes.length; i++) {
        if (userAttributes[i].Name === "given_name") {
          this.form.firstName = userAttributes[i].Value;
        }
        if (userAttributes[i].Name === "family_name") {
          this.form.lastName = userAttributes[i].Value;
        }
        if (userAttributes[i].Name === "phone_number") {
          this.form.phone = userAttributes[i].Value;
        }
        if (userAttributes[i].Name === "email") {
          this.form.email = userAttributes[i].Value;
        }
      }
    },
    async onUserSubmit() {
      if (this.$refs.form.validate()) {
        let user = await Auth.currentAuthenticatedUser();

        let result = await Auth.updateUserAttributes(user, {
          given_name: this.form.firstName,
          family_name: this.form.lastName,
          phone_number: this.form.phone,
          // "custom:avatar": upload !== null ? upload.key : ""
        });
        this.findUser();

        if (result === "SUCCESS") {
          this.snackbarText = "Profile updated";
          this.snackbar = true;
        }
      }
    },
  },
};
</script>
