<template>
  <profile />
</template>

<script>
import Profile from "../views/Profile";

export default {
  name: "home",

  components: {
    Profile,
  },
};
</script>
